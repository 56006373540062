import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
// import { useTheme } from '@mui/system';
import alexw from './alexwprofile.jpg';

const userTestimonials = [
  {
    avatar: <Avatar alt="Francesca Finato" src="https://www.southbaypoloclub.com/wp-content/uploads/2022/02/cropped-sbpolo_logo-300x300-1.png" />,
    name: 'Francesca Finato',
    occupation: 'Manager, South Bay Polo Club',
    testimonial:
      "Chukkerbook has changed the time it takes me to create and publish line ups. Collecting sign ups and having all special requests in one place has made my life as a club manager so much easier. Every club manager needs Chukkerbook in their life. ",
  },
  // {
  //   avatar: <Avatar alt="Birgit Werner" src="http://www.horseparkpoloclub.com/wp-content/uploads/2018/11/hppc_logo.png" />,
  //   name: 'Birgit Werner',
  //   occupation: 'Manager, Horse Park Polo Club',
  //   testimonial:
  //     "Test test test.",
  // },
  {
    avatar: <Avatar alt="Alex Wang" src={alexw} />,
    name: 'Alexander Wang',
    occupation: 'Polo Player',
    testimonial:
      'Chukkerbook has revolutionized my polo life. As a full member of 3 different local clubs, Chukkerbook made it much easier for me to keep track of what I signed up for at each club. The visibility from the platform also makes it easy to coordinate with other members for chukkers, group lessons and special events. I highly recommend any polo club to integrate Chukkerbook to streamline and bring clarity and structure to the sign-up process.',
  },
];

// const whiteLogos = [
//   'https://www.southbaypoloclub.com/wp-content/uploads/2022/02/cropped-sbpolo_logo-300x300-1.png',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
// ];

// const darkLogos = [
//   'https://www.southbaypoloclub.com/wp-content/uploads/2022/02/cropped-sbpolo_logo-300x300-1.png',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
//   'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
// ];

// const logoStyle = {
//   width: '64px',
//   opacity: 0.3,
// };

export default function Testimonials() {
  // const theme = useTheme();
  // const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'white' }}
        >
          Testimonials
        </Typography>
        {/* <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          See what club managers and players have to say about using Chukkerbook to streamline day to day signups and club management
        </Typography> */}
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 6 }} key={index} sx={{ display: 'flex' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                {/* <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                /> */}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
